import React, { memo } from "react";
import { isEqual } from "lodash";
import { useTranslation } from "next-i18next";
import withHydrationOnDemand from "react-hydration-on-demand";
import styled from "styled-components";
import { Plate, PlateProvider } from "@udecode/plate";
import { MyValue } from "../../global/plate/plateTypes";
import { plugins } from "../../global/plate/plugins/plugins";
import { isPortalSide } from "../../helpers/helpers";
import { IBoxHtmlProps } from "./BoxHtml.types";

export const ListWrapper = styled.div`
  ul,
  ol {
    list-style: revert !important;
  }
`;

const BoxHtml: React.FC<IBoxHtmlProps> = (props) => {
  const { t } = useTranslation();

  const HtmlViewer: React.FC = () => {
    // this component is created every render, so PlateProvider is created every render with new value
    return (
      <ListWrapper className="html-container__list-wrapper html-custom-style">
        <PlateProvider<MyValue>
          plugins={plugins}
          initialValue={JSON.parse(props.messages.box_html_content_html)}
          readOnly
        >
          <Plate<MyValue> />
        </PlateProvider>
      </ListWrapper>
    );
  };

  if (props.messages.box_html_content_html === "")
    return (
      <div className="html-container__empty">
        {!isPortalSide() && t("global_keys.empty_box")}
      </div>
    );
  return <HtmlViewer />;
};

const MemoizedBoxHtml = memo(BoxHtml, isEqual);

export default withHydrationOnDemand({ on: ["visible"] })(MemoizedBoxHtml);
